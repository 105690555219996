// require jQuery normally

// create global $ and jQuery variables
const $ = require('jquery');
global.$ = global.jQuery = global.jquery = $;

window.jQuery = $;
window.$ = $;

require('./jquery-ui-1.11.1.js');
require('./jquery-ui-1.11-datepicker-es.js');
require('./jquery.ui.timepicker.js');
require('./jquery-ui-1.11-timepicker-es.js');

const bootstrap = require('./bootstrap.js');
global.bootstrap = bootstrap;

const select2 = require('./select2.js');
global.select2 = select2;


