/* Inicialización en español para la extensión 'UI date picker' para jQuery. */
/* Traducido por Vester (xvester@gmail.com). */

	/* Datepicker regionalization. */
	jQuery(function($){
		$.timepicker.regional['es'] = {
			timeOnlyTitle: 'Elegir hora',
			timeText: 'Hora',
			hourText: 'Horas',
			minuteText: 'Minutos',
			secondText: 'Segundos',
			currentText: 'Ahora',
			closeText: 'Cerrar'
		};
		$.timepicker.setDefaults($.timepicker.regional['es']);
	});
